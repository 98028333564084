<template>
    <div class="container">
        <h1 class=" text-center display-3 mt-12">
            Error <span class="red--text"> 403</span>-Forbidden
            <!-- <p class="error--text">
                 403
            </p> -->
        </h1>

        <div class="text-center my-12">
            <p>
                ليس لديك إذن للوصول إلى هذة الصفحة أو هذا الإجراء
            </p>
            يحدث الخطأ 403 Forbidden Error عندما تكون صفحة الويب (أو مورد آخر)
            التي تحاول فتحها في متصفح الويب الخاص بك مصدرًا لا يُسمح لك بالوصول
            إلية.
        </div>

        <v-img width="230" class="mx-auto" src="../assets/img/stop.svg" />

        <div class="d-flex justify-center mt-12">
            <v-btn color="primary" large class="secondary--text" to="/">
                الصفحة الرئيسية
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
        }
    }
};
</script>

<style></style>
